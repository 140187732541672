import React from "react";
import styles from "../../css/shared/styles.module.scss";

function SectionWrapper({ height, type, children }) {
  return (
    <div
      className={`${
        styles[`${type}-section-wrapper`] +
        " " +
        (height ? styles["full-height"] : null)
      }`}
    >
      {children}
    </div>
  );
}

export default SectionWrapper;
