import styles from "../../../css/shared/styles.module.scss";

function NavButton({ name, isActive, btnClicked, children }) {
  return (
    <button
      onClick={() => btnClicked(name)}
      className={`${isActive ? styles["active"] : null}`}
    >
      {children}
    </button>
  );
}

export default NavButton;
