import React from "react";
import styles from "../../css/shared/styles.module.scss";

function Section({ name = null, title = null, invert = false, children }) {
  return (
    <div
      id={name || name}
      className={
        "section " + styles["section"] + " " + styles[`section-${name}`]
      }
    >
      {title ? (
        <h2 style={invert ? { color: "white" } : null}>{title}</h2>
      ) : null}
      {children}
    </div>
  );
}

export default Section;
