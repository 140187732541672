import React from "react";
import Show from "./components/Show";

const PAST = "past";
const UPCOMING = "upcoming";

function PastShows() {
  return (
    <div>
      <Show
        weekday="Sat"
        month="June"
        day="10"
        year="2023"
        venue="Lake House"
        when="Saturday @ 6:30pm - 12am"
      />
      <Show
        weekday="Tues"
        month="April"
        day="18"
        year="2023"
        venue="Billy's Tip 'n Inn"
        when="Tuesday @ 7:30pm - 9pm"
        location_link="https://goo.gl/maps/3ZvJudmhaWobgK6y7"
        where="6707 Highland Rd White Lake, MI"
      />
      <Show
        weekday="Sat"
        month="Feb"
        day="25"
        year="2023"
        venue="The Elks Club"
        when="Saturday @ 8:30pm - 12:30pm"
        where="22856 Woodward Ave Ferndale, MI"
        location_link="https://goo.gl/maps/gsQehApF4LbQJpgL7"
      />
      <Show
        weekday="Sat"
        month="Nov"
        day="5"
        year="2022"
        venue="The Elks Club"
        when="Saturday @ 7:30pm - 11pm"
        where="22856 Woodward Ave Ferndale, MI"
        location_link="https://goo.gl/maps/gsQehApF4LbQJpgL7"
      />
      <Show
        weekday="Sat"
        month="Sept"
        day="24"
        year="2022"
        venue="Lake House"
        when="Saturday @ 6:30pm - 12am"
      />
      <Show
        weekday="Thurs"
        month="July"
        day="14"
        year="2022"
        venue="On the Rocks"
        when="Thursday @ 7pm - 11pm"
        where="22856 Woodward Ave Ferndale, MI"
        location_link="https://goo.gl/maps/J94KFNbpPz5vfMYf8"
      />
      {/*<Show
        weekday="Thurs"
        month="June"
        day="9"
        year="2022"
        venue="On the Rocks"
        when="Thursday @ 7pm - 11pm"
        where="22856 Woodward Ave Ferndale, MI"
        location_link="https://goo.gl/maps/J94KFNbpPz5vfMYf8"
      />
      <Show
        weekday='Sat'
        month='Feb'
        day='4'
        year='2022'
        venue='The Elks Club'
        when='Saturday @ 7:30pm - 11pm'
        where='22856 Woodward Ave Ferndale, MI'
        location_link='https://goo.gl/maps/gsQehApF4LbQJpgL7'
      />
      <Show
        weekday='Tues'
        month='Nov'
        day='15'
        year='2021'
        venue="Billy's Tip 'n Inn"
        when='Tuesday @ 7:30pm - 9pm'
        location_link='https://goo.gl/maps/3ZvJudmhaWobgK6y7'
        where='6707 Highland Rd White Lake, MI'
      /> 
      */}
    </div>
  );
}

function UpcomingShows() {
  return (
    <div>
      <Show
        weekday="Sat"
        month="July"
        day="22"
        year="2023"
        venue="The Elks Club"
        when="Saturday @ 8:30pm - 12:30pm"
        where="22856 Woodward Ave Ferndale, MI"
        location_link="https://goo.gl/maps/gsQehApF4LbQJpgL7"
      />
      <Show
        weekday="Sat"
        month="Sept"
        day="30"
        year="2023"
        venue="The Elks Club"
        when="Saturday @ 8:30pm - 12:30pm"
        where="22856 Woodward Ave Ferndale, MI"
        location_link="https://goo.gl/maps/gsQehApF4LbQJpgL7"
      />
    </div>
  );
}

function Shows({ type }) {
  return (
    <div>
      {type === PAST ? (
        <PastShows />
      ) : type === UPCOMING ? (
        <UpcomingShows />
      ) : (
        <p>New shows coming soon!</p>
      )}
    </div>
  );
}

export default Shows;
