import { useEffect, useState } from "react";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import Gallery from "./components/ImageGallery/ImageGallery";
import Nav from "./components/Nav/Nav";
import Page from "./components/Page/Page";
import Section from "./components/Section/Section";
import SectionWrapper from "./components/SectionWrapper/SectionWrapper";
import Shows from "./components/Shows/Shows";
import styles from "./css/shared/styles.module.scss";
import EmailIcon from "./svgs/email.svg";
import FacebookIcon from "./svgs/facebook.svg";
import Logo from "./svgs/logo.svg";
import PhoneIcon from "./svgs/phone.svg";
import YoutubeIcon from "./svgs/youtube.svg";

function App() {
  const [activeSection, setActiveSection] = useState("home");
  const [isMobile, setIsMobile] = useState(false);
  const [navHasBackground, setNavHasBackground] = useState(false);
  const TOP_OFFSET_DESKTOP = 40;
  const TOP_OFFSET_MOBILE = 80;

  useEffect(() => {
    const sections = document.querySelectorAll(".section");

    resize();

    window.onscroll = () => {
      const pageYOffset = window.pageYOffset;

      Array.from(sections)
        .filter((section) => section.id !== "")
        .map((section) => {
          const topPos = section.getBoundingClientRect().top;
          const btmPos = section.getBoundingClientRect().bottom;
          const topOffset = isMobile
            ? TOP_OFFSET_MOBILE + 1
            : TOP_OFFSET_DESKTOP + 1;

          if (
            topPos + pageYOffset - topOffset < pageYOffset &&
            btmPos + pageYOffset - topOffset > pageYOffset
          ) {
            // the pageYOffset is within the section's vertical area
            const sectionName = section.getAttribute("id");
            setActiveSection(sectionName);
          }
        });

      if (window.scrollY > 0) {
        setNavHasBackground(true);
      } else {
        setNavHasBackground(false);
      }

      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 20
      ) {
        // you're at the bottom of the page
        setActiveSection("contact");
      }
    };

    window.onresize = () => {
      resize();
    };
  }, []);

  const resize = () => {
    if (window.innerWidth > 700) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  const navItemClicked = (type) => {
    const element = document.querySelector(`${"#" + type}`);
    const topOffset =
      type === "home" ? 0 : isMobile ? TOP_OFFSET_MOBILE : TOP_OFFSET_DESKTOP;

    const topPos =
      element.getBoundingClientRect().top + window.pageYOffset - topOffset;

    window.scrollTo({
      top: topPos,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Page>
        <Nav
          activeNavItem={activeSection}
          hasBackground={navHasBackground}
          navItemClicked={(type) => navItemClicked(type)}
        />
        <SectionWrapper height="fullHeight" type="home">
          <Section name="home">
            <img
              className={styles["main-logo"]}
              alt="rumble road logo"
              src={Logo}
            />
          </Section>
        </SectionWrapper>
        <SectionWrapper type="shows">
          <Section name="shows">
            <Section title="Upcoming Shows">
              <Shows type="upcoming" />
            </Section>
            <Section title="Past Shows" invert={true}>
              <Shows type="past" />
            </Section>
          </Section>
        </SectionWrapper>
        <SectionWrapper type="info">
          <Section name="gallery" title="Gallery">
            <Gallery />
            <p>
              Rumble Road was spawned out of the collective consciousness of 5
              seasoned musicians looking to bring some color and texture to the
              Southeast Michigan musical landscape. Drawing on multiple genres,
              the band delivers a sound tapestry that mixes punch and crunch,
              ethereal spaciness and solid grooves to create an audience
              experience that wakes up the ears, bobbles the heads and beckons
              the feet to do what they do best - get up and dance! Set lists
              span the decades with influences that include Bowie, Queen, ELO,
              Portugal the Man, Divine Fits, Cake and the Stones to name a
              few... Rumble Road takes you places where the experience is fun,
              memorable and filled with new adventures. Let's go!
            </p>
          </Section>
          <Section name="contact" title="Contact">
            <div className={styles["contact-container"]}>
              <a
                className={styles["flex-start-center"]}
                alt="rumble road phone number"
                href="tel:2484175569"
              >
                <img alt="phone icon" src={PhoneIcon} />
                <div>(248) 417-5569</div>
              </a>
              <a
                className={styles["flex-start-center"]}
                alt="rumble road email"
                href="mailto:rumbleroadband@gmail.com"
              >
                <img alt="email icon" src={EmailIcon} />
                <div>rumbleroadband@gmail.com</div>
              </a>
              <div className={styles["social-share"]}>
                <a
                  href="https://www.facebook.com/rumbleroadband"
                  target="_blank"
                >
                  <img alt="facebook icon" src={FacebookIcon} />
                </a>
                <a
                  href="https://www.youtube.com/@rumbleroad2011"
                  target="_blank"
                >
                  <img alt="youtube icon" src={YoutubeIcon} />
                </a>
              </div>
            </div>
          </Section>
          <Footer></Footer>
        </SectionWrapper>
        <div className={styles["social-share"] + " " + styles["fixed-share"]}>
          <a href="https://www.facebook.com/rumbleroadband" target="_blank">
            <img alt="facebook icon" src={FacebookIcon} />
          </a>
          <a href="https://www.youtube.com/@rumbleroad2011" target="_blank">
            <img alt="youtube icon" src={YoutubeIcon} />
          </a>
        </div>
      </Page>
    </div>
  );
}

export default App;
