import React, { useState } from "react";
import styles from "../../css/shared/styles.module.scss";
import LogoInitials from "../../svgs/logo-initials.svg";
import HamburgerMenu from "../../svgs/menu.svg";
import NavButton from "../Nav/components/NavButton";

function Nav({ activeNavItem, hasBackground, navItemClicked }) {
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  const hamburgerClick = () => {
    setHamburgerMenuOpen(!hamburgerMenuOpen);
  };

  const navClick = (type) => {
    navItemClicked(type);
    setHamburgerMenuOpen(false);
  };

  return (
    <div
      className={
        "nav" +
        " " +
        `${hasBackground ? styles["with-background"] : ""}` +
        " " +
        styles["nav"]
      }
    >
      <NavButton
        name="home"
        isActive={activeNavItem === "home"}
        btnClicked={navClick}
      >
        <div className="logo-initials">
          <img
            className={styles["logo-initials"]}
            alt="rumble-road-logo-intials"
            src={LogoInitials}
          />
        </div>
      </NavButton>

      <div className={styles["hamburger-menu-container"]}>
        <button onClick={() => hamburgerClick()}>
          <img
            className={styles["hamburger-menu-icon"]}
            alt="mobile-hamburger-menu-button"
            src={HamburgerMenu}
          />
        </button>
      </div>

      {hamburgerMenuOpen ? (
        <div className={styles["hamburger-menu"]}>
          <NavButton
            name="home"
            isActive={activeNavItem === "home"}
            btnClicked={navClick}
          >
            Home
          </NavButton>
          <NavButton
            name="shows"
            isActive={activeNavItem === "shows"}
            btnClicked={navClick}
          >
            Shows
          </NavButton>
          <NavButton
            name="gallery"
            isActive={activeNavItem === "gallery"}
            btnClicked={navClick}
          >
            Gallery
          </NavButton>
          <NavButton
            name="contact"
            isActive={activeNavItem === "contact"}
            btnClicked={navClick}
          >
            Contact
          </NavButton>
        </div>
      ) : null}

      <div className={styles["nav-btn-group"]}>
        <NavButton
          name="home"
          isActive={activeNavItem === "home"}
          btnClicked={navClick}
        >
          Home
        </NavButton>
        <NavButton
          name="shows"
          isActive={activeNavItem === "shows"}
          btnClicked={navClick}
        >
          Shows
        </NavButton>
        <NavButton
          name="gallery"
          isActive={activeNavItem === "gallery"}
          btnClicked={navClick}
        >
          Gallery
        </NavButton>
        <NavButton
          name="contact"
          isActive={activeNavItem === "contact"}
          btnClicked={navClick}
        >
          Contact
        </NavButton>
      </div>
    </div>
  );
}

export default Nav;
