import React from "react";
import styles from "../../../css/shared/styles.module.scss";
import ClockIcon from "../../../svgs/clock.svg";
import MapIcon from "../../../svgs/map.svg";

function Show({
  weekday = "",
  month = "",
  day = "",
  year = "",
  venue = "",
  when = "",
  where = null,
  location_link = null,
}) {
  return (
    <div className={styles.show}>
      <div className={styles.calendar}>
        <div className={styles["tab-group"]}>
          <div className={styles["month"]}>{month}</div>
        </div>
        <div className={styles["day"]}>{day}</div>
        <div className={styles["year"]}>{year}</div>
      </div>
      <div className={styles["venue"]}>
        <h3 className={styles["name"]}>{venue}</h3>
        <div className={styles["flex-start-start"] + " " + styles["when"]}>
          <div
            className={
              styles["img-container"] + " " + styles["flex-center-center"]
            }>
            <img alt='clock icon' src={ClockIcon} />
          </div>
          <div>{when}</div>
        </div>
        {where ? (
          <div className={styles["flex-start-start"] + " " + styles["where"]}>
            <div
              className={
                styles["img-container"] + " " + styles["flex-center-center"]
              }>
              <img alt='map icon' src={MapIcon} />
            </div>
            <a alt='show location' href={location_link} target='_blank'>
              {where}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Show;
