import React from "react";
import styles from "../../css/shared/styles.module.scss";

function Footer() {
  return (
    <div className={styles["footer"] + " " + styles["flex-center-center"]}>
      <p>Copyright 2023 Rumble Road</p>
    </div>
  );
}

export default Footer;
